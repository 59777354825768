import React, { useState } from 'react';
import '../styles/survey.css';
import '../styles/global-styles.css';
import '../styles/top-bar.css';
import '../styles/RadialBackground.css';
import '../styles/AnimationStyles.css';
import RadialBackground from '../components/RadialBackground';
import Survey from '../components/survey.jsx';
import AnimationSection from '../components/AnimationSection'; // Ensure the path is correct
import animationData from '../animations/test.json'; // Ensure the path is correct
import CenteredLogo from '../components/logo'; // Import the logo component
import Canvas from '../components/Canvas'; // Import the Canvas component

const FrontPage = () => {
  const [animationVisible, setAnimationVisible] = useState(false);

  return (
    <div className="main-section">
      <div className="logo-divider">
        <CenteredLogo />
      </div>
      <div className="animation-section-wrapper">
        <div className="animation-section">
          {/* Animation Section for Static or Default Animation */}
          <AnimationSection
            animationData={animationData}
            visible={animationVisible} // Control animation visibility
          />
        </div>
        {/* Canvas for Dynamic Animations */}
        <Canvas />
      </div>
      <div className="survey-section-wrapper">
        {/* Survey Controls Animation Visibility */}
        <Survey setAnimationVisible={setAnimationVisible} />
      </div>
      {/* Radial Background */}
      <RadialBackground />
    </div>
  );
};

export default FrontPage;
