import React from 'react';
import FrontPage from './page/frontPage';

function App() {
  return (
    <div className="App">
      <FrontPage />
    </div>
  );
}

export default App;

